// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-container{
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #012D26;
}

.title{
    font-size: 1.8em;
}

.Wsub-title{
    font-size: 1.075em;
    color: #E65924;
}`, "",{"version":3,"sources":["webpack://./src/App/Component/Title/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".title-container{\n    width: 100%;\n    height: 70px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: #012D26;\n}\n\n.title{\n    font-size: 1.8em;\n}\n\n.Wsub-title{\n    font-size: 1.075em;\n    color: #E65924;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
