import './style.css';
import {CONTACT, PRODUCT, ROOT, SERVICE} from '../../const';

const NavigationBar = (props)=>{
	const {navBarType, language, setLanguage} = props;

	let getFontColor = (name)=>{
		let fontColor = '#012D26';
		if(navBarType !== 'default') {
			fontColor = '#F5F5F5';
			if(name === window.location.pathname){
				fontColor = '#E8DE00'
			}
		}
		return fontColor;
	}
	let getFontWeight = (name)=>{
		return name === window.location.pathname ? 800 : 450;
	}
	let width = '68px';
	let height = '68px';
	let logoUrl = navBarType === 'default' ? 'assets/logo_green.png' : 'assets/logo_white.png'

	let title = navBarType === 'default' ? '' : 'PT. Packing Material Indonesia';

	return(
		<div id={'nav-bar'}>
			<div id={'nav-bar-title-container'}>
				<img src={logoUrl}
				     style={{
					     width: width,
					     height: height,
					     marginRight: '5px'
				     }}
				/>
				{title}
			</div>
			<div id={'nav-bar-menu-container'}>
				<div className={'nav-menu'}>
					<a href={ROOT} style={{
						color: getFontColor(ROOT),
						fontWeight: getFontWeight(ROOT)
					}}>
						Home
					</a>
				</div>
				<div className={'nav-menu'}>
					<a href={PRODUCT} style={{
						color:  getFontColor(PRODUCT),
						fontWeight: getFontWeight(PRODUCT)
					}}>
						Our Products
					</a>
				</div>

				<div className={'nav-menu'}>
					<a href={SERVICE}  style={{
						color:  getFontColor(SERVICE),
						fontWeight: getFontWeight(SERVICE)
					}}>
						Services
					</a>
				</div>
				<div className={'nav-menu'}>
					<a href={CONTACT} style={{
						color:  getFontColor(CONTACT),
						fontWeight: getFontWeight(CONTACT)
					}}>
						Contact
					</a>
				</div>

				<div id={'nav-bar-select-container'}>
					<select className={`nav-bar-select ${navBarType === 'default' ? 'nav-bar-select-transparent' : 'nav-bar-select-color'}`}
					        onChange={(e)=>{
						        setLanguage(e.target.value);
					        }}>
						<option hidden value={language}>{language === 'id' ? "ID" : "EN"}</option>
						<option value={'id'} className={`${navBarType === 'default' ? '' : 'option-color-bg'}`}>ID</option>
						<option value={'en'} className={`${navBarType === 'default' ? '' : 'option-color-bg'}`}>EN</option>
					</select>
				</div>

			</div>
		</div>
	);
}

export default NavigationBar;