import './style.css';

const Title = (props)=> {
	const {title, subTitle} = props;

	return (
		<div className={'title-container'}>
			<div className={'title'}>
				{title}
			</div>
			<div className={'sub-title'}>
				{subTitle}
			</div>
		</div>
	);
}

export default Title;