import Article from "../../Component/Article";
import Title from "../../Component/Title";

const Product = (props)=>{
	const {language} = props;
	const FirstComponent = ()=>{
		return(<></>);
	}

	const title = language === "id" ? 'Kayu Palet dan Box dengan bermacam jenis ukuran dan tipe' : 'Wooden Pallets and Boxes of Any Specification';
	const subTitle = language === "id" ?  'With Quality Control, Extensive Heat Treatment and ISPM15 Certification' : 'With Quality Control, Extensive Heat Treatment and ISPM15 Certification';

	const Components = (language)=>{
		let articleDescription1 = language === 'id' ? 'Karena kami berspesialisasi dalam kustomisasi packing, kami mampu memenuhi persyaratan dan spesifikasi palet kayu Anda untuk memberikan solusi pengemasan terbaik untuk komoditas Anda.' : 'As we highly specialise in packaging customisation, we are versatile with your wooden pallet requirements and specifications to provide the best packaging solution for your commodities.'

		let articleTitle2 = language === 'id' ? 'Karton Box' : 'Corrugated Box';
		let articleDescription2 = language === 'id' ? 'Karena kotak kayu lebih berat, kami menyediakan alternatif lain untuk solusi pengemasan Anda seperti kotak karton. Berat kotak karton kami berkisar antara 100gsm - 450gsm yang akan memberikan daya tahan yang Anda butuhkan.' : 'As wooden boxes are heavier, we provide other alternatives for your packaging solutions such as carton boxes. Our carton boxes ranges from 100gsm - 450gsm in weight which will provide the durability that you need.'

		let articleTitle3 = language === 'id' ? 'Kotak & Peti Kayu' : 'Wooden Boxes & Crates';
		let articleDescription3 = language === 'id' ? 'Di perusahaan kami, tim ahli kami akan secara khusus menyesuaikan kotak atau peti kayu untuk komoditas Anda. Kami telah merancang lebih dari ratusan kotak dan peti kayu, mulai dari berbagai ukuran dan berat, sesuai dengan kebutuhan Anda.' : 'In PT. Packing Material Indonesia, our special team will specially customise wooden boxes or crates for your commodity. We have ideated over hundreds of wooden boxes and crates, ranging from different sizes and weights, according to your needs.'

		return(
			<div className={'page-component'}>
				<Title title={title} subTitle={subTitle}/>
				<Article
					imageSources={[
						'/assets/product/product-1-1.png',
						'/assets/product/product-1-2.png',
						'/assets/product/product-1-3.png',
						'/assets/product/product-1-4.png',
						'/assets/product/product-1-5.png',
					]}
					title={'2-Way, 4-Way, Plywood Pallets, and Many More'}
					textDescription={articleDescription1}
				/>
				<Article
					imageSources={[
						'/assets/product/product-2-0.png',
					]}
					title={articleTitle2}
					textDescription={articleDescription2}
					isReversed={true}
				/>
				<Article
					imageSources={[
						'/assets/product/product-3-1.png',
						'/assets/product/product-3-2.png',
						'/assets/product/product-3-3.png',
						'/assets/product/product-3-4.png',
						'/assets/product/product-3-5.png',
					]}
					title={articleTitle3}
					textDescription={articleDescription3}
				/>
			</div>
		);

	}

	const bgImageUrl = '/assets/bg_product.png';

	return {
		FirstComponent: FirstComponent,
		Components: Components,
		bgImageUrl: bgImageUrl
	}
}

export default Product;