import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import {useEffect, useState} from "react";

import './style.css';

import NavigationBar from "./Component/NavigationBar";
import Footer from "./Component/Footer";

import {ROOT, CONTACT, PRODUCT, SERVICE} from "./const";

import Home from "./Page/Home";
import Product from "./Page/Product";
import Service from "./Page/Service";
import Contact from "./Page/Contact";

const Main = () => {
	let [showElement, setShowElement] = useState(true);
	let [width, setWidth] = useState(100);
	let [height, setHeight] = useState(100);
	let [navBarType, setNavBarType] = useState('default');
	let [language, setLanguage] = useState('id');

	useEffect(() => {
		const currRoute = window.location.pathname;
		const width = window.innerWidth;
		const height = window.innerHeight;

		const initialize = ()=>{
			initializeLanguage();
			initializeDimension();
		}

		const initializeLanguage = ()=>{
			const savedLanguage = localStorage.getItem('language');
			if(savedLanguage){
				setLanguage(savedLanguage);
			}
			if(currRoute === ROOT){
				setNavBarType('default');
				setComponents(Home);
			}else{
				setNavBarType('color');
				if(currRoute === PRODUCT){
					setComponents(Product);
				}else if(currRoute === SERVICE){
					setComponents(Service);
				}else if(currRoute === CONTACT){
					setHeight(78);
					setComponents(Contact);
				}else{
					setShowElement(false);
				}
			}
		}
		const initializeDimension = ()=>{
			window.addEventListener('resize', ()=>{
				console.log({
					height,
					width
				})
				setHeight(height);
				setWidth(width);
			});
		}
		initialize();
	}, []);

	const updateLanguage = (value)=> {
		setLanguage(value);
		localStorage.setItem('language', value);
	}

	let [Components, setComponents] = useState({
		FirstComponent: ()=><div></div>,
		Components: ()=><div></div>
	});

	const index = createBrowserRouter([
		{
			path: ROOT,
			element: Components.Components(language)
		},
		{
			path: PRODUCT,
			element: Components.Components(language)
		},
		{
			path: SERVICE,
			element: Components.Components(language)
		},
		{
			path: CONTACT,
			element: Components.Components(language)
		}
	]);
	return (
		<>
			{
				showElement ?
					<div className={'page'}>
						<div className={'first-component'} style={{
							backgroundImage: `url(${Components.bgImageUrl})`,
							height: `${Components.height ? Components.height : '850px'}`
						}}>
							<NavigationBar navBarType={navBarType} setLanguage={updateLanguage} language={language}/>
							{Components.FirstComponent ? Components.FirstComponent(language) : <></>}
						</div>
						<div className={'page-container'}>
							<RouterProvider router={index}/>
						</div>
						<Footer/>
					</div>
					:
					<div>
						Not Found
					</div>
			}
		</>
	);
}

export default Main;